<template>
  <div class="product-section">
    <h2>
      {{ langStore.lang === "ru" ? "Наша продукция" : "Our production" }}
    </h2>
    <div class="grid-wrapper">
      <MainBlockComponent v-for="(item, index) in products" :key="index" :header="item.header" :text="item.text" :image="item.image" />
    </div>
  </div>
</template>

<script>
import MainBlockComponent from '@/components/MainBlockComponent.vue';
import {useLangStore} from '@/stores/lang';

export default {
  name: 'HomeView',
  components: {
    MainBlockComponent
  },
  data() {
    return {
      langStore: useLangStore(),
      products: [
        {
          image: require("@/img/forest.jpg"),
          header: "Круглый лес",
          text: "Продажа круглого леса со склада",
        },
        {
          image: require("@/img/pylomat.jpg"),
          header: "Пиломатериалы",
          text: "Компания предлагает самый широкий спектр пиломатериалов различных пород",
        },
        {
          image: require("@/img/furniture.jpg"),
          header: "Мебельный щит",
          text: "Осуществляем производство высококачественного мебельного щита из собственного сырья",
        },
        {
          image: require("@/img/shpon.jpg"),
          header: "Шпон",
          text: "Компания имеет производственные линии по изготовлению радиального, тангентального и пиленного шпона различного назначения",
        },
        {
          image: require("@/img/charcoal.jpg"),
          header: "Древесные угольные пеллеты",
          text: "Из отходов производства компания производить топливные угольные брекеты, обладающие высокой теплоотдачей",
        },
        {
          image: require("@/img/otdel.jpg"),
          header: "Отделочные материалы",
          text: "Компания располагает линией по производству широкой линейки отделочных материалов",
        },
      ]
    }
  },
  mounted() {
    this.fetchContent();
  },
  methods: {
    async fetchContent() {
      try {
        const response = await fetch(process.env.VUE_APP_API_URL + `/api/get/resurs/${this.$langStore.lang}/products`);
        if (!response.ok) throw new Error('Ошибка запроса');
        const data = await response.json();
        this.products.forEach((element, index, arr) => {
          if (!element) return;
          arr[index].header = data.products[index].header;
          arr[index].text = data.products[index].text;
        });

      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      }
    }
  }
}
</script>

<style scoped lang="scss">
.product-section {
  padding: 20px;
}

.grid-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  width: 90%;
  margin: 0 auto;
}

@media (max-width: 1024px) {
 .grid-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
 .grid-wrapper {
    grid-template-columns: 1fr;
  }
}
</style>