import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createYmaps } from 'vue-yandex-maps';
import App from './App.vue'
import router from './router'
import {useLangStore} from '@/stores/lang';

const app = createApp(App);
const pinia = createPinia()

app.use(router);

app.use(createYmaps({
    apikey: process.env.VUE_APP_YANDEX_API
}));

app.use(pinia)

app.config.globalProperties.$langStore = useLangStore();

app.mount('#app')
