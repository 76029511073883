<template>
  <div class="image-text-block">
    <img :src="image" alt="Фоновое изображение" />
    <div class="text-overlay">
      <h2>{{ header }}</h2>
      <p>{{ text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainBlockComponent',
    props: {
        header: String,
        text: String,
        image: String
    }
}
</script>

<style scoped lang="scss">
.image-text-block {
  position: relative;
  width: 100%;
  height: 100%;
}

.image-text-block img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.text-overlay h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.text-overlay p {
  font-size: 18px;
  margin-bottom: 20px;
}
</style>