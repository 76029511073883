<template>
  <div class="top-block" id="main">
    <p v-if="$route.name === 'home'">
      {{ home }}
    </p>
    <p v-else>
      {{ notHome }}
    </p>
  </div>
</template>
  
<script>
import {useLangStore} from '@/stores/lang';

export default {
  name: 'FirstBlockComponent',
  data() {
    return {
      langStore: useLangStore(),
      home: this.$langStore.lang === "ru" ? "Мы предлагаем следующую продукцию собственного производства" 
      : "We offer the following products of our own production",
      notHome: this.$langStore.lang === "ru" ? "Продукция лесопильно-древообрабатывающей промышленности по выгодным ставкам" 
      : "Products of the sawmill and woodworking industry at favorable rates",
    }
  }
}
</script>
  
<style scoped lang="scss"> 
  .top-block {
    position: relative;
    background-image: url('~@/img/forest-bg.jpg');
    min-height: calc(100dvh - 3rem);
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      color: #fff;
      font-weight: bold;
      font-size: 2rem;
      z-index: 1;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, .5);
      pointer-events: none;
      z-index: 0;
    }
  }
</style>