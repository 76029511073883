<template>
    <footer class="footer">
        <div class="grid-wrapper">
        <div class="block">
            <img src="~@/img/victor.jpg" alt="Victor" class="circle-image">
        </div>
        <div class="block">
            <h2>
                {{ langStore.lang === "ru" ? "О нас" : "About us" }}
            </h2>
            <p>
                {{ about }}
            </p>
        </div>
        <div class="block">
            <yandex-map
                v-model="map"
                :settings="{
                    location: {
                    center: [134.851476, 45.899847],
                    zoom: 16,
                    },
                }"
                width="30dvw"
                height="40dvh"
            >
                <yandex-map-default-scheme-layer :settings="{
                    type: 'ground'
                }"/>
                <yandex-map-default-features-layer/>
                <yandex-map-controls :settings="{ position: 'right' }">
                    <yandex-map-zoom-control />
                </yandex-map-controls>
                <yandex-map-default-marker :settings="{
                    coordinates: [134.851476, 45.899847],
                }" />
            </yandex-map>
        </div>
        <div class="block">
            <div class="contacts-block">
                <h2>
                    {{ langStore.lang === "ru" ? "Контакты" : "Contacts" }}
                </h2>
                <ul>
                    <li><a href="mailto:info@resurs-group.com">info@resurs-group.com</a></li>
                    <li><a href="tel:+79147058195">+7 914 705 8195</a></li>
                    <li>{{ address }}</li>
                </ul>
            </div>
        </div>
        </div>
    </footer>
</template>

<script>
import {useLangStore} from '@/stores/lang';
import { shallowRef } from 'vue';
import { YandexMap, YandexMapDefaultSchemeLayer, YandexMapDefaultFeaturesLayer, YandexMapControls, YandexMapZoomControl, YandexMapDefaultMarker } from 'vue-yandex-maps';

export default {
    name: "FooterComponent",
    components: {
        YandexMap, 
        YandexMapDefaultSchemeLayer,
        YandexMapZoomControl,
        YandexMapControls,
        YandexMapDefaultMarker,
        YandexMapDefaultFeaturesLayer
    },
    data() {
        return {
            langStore: useLangStore(),
            about: this.$langStore.lang === "ru" ? `Компания была создана в 1991 году как семейный бизнес и уже много лет занимает лидирующие позиции в лесном секторе экономики Приморского края.
В настоящее время в группе компаний работает более 150 человек, высокая квалификация которых являются гарантией качества выпускаемой продукции.
Компания постоянно модернизирует и расширяет производство, повышает степень переработки. В 2022 году был введен в эксплуатацию третий лесоперерабатывающий комплекс, завод по производству шпона и щита.
Компания владеет значительными ресурсами древесного сырья - лесосырьевая база занимает территорию площадью более 70000 га, при этом ежегодный допустимый объем заготовки круглого леса достигает 85000 м3. Срок аренды лесного фонда составляет 10-15 лет.` 
: `The company was established in 1991 as a family business and has been a leader in the forestry sector of the economy of Primorsky Krai for many years.

Currently, the group of companies employs more than 150 people, whose high qualifications are a guarantee of the quality of products.
The company is constantly modernizing and expanding production, increasing the degree of processing. In 2022, the third wood processing complex, a veneer and board production plant was put into operation.
The company owns significant resources of wood raw materials - the timber base covers an area of more than 70,000 hectares, while the annual allowable volume of roundwood harvesting reaches 85,000 m3. The forest lease period is 10-15 years.`,
            address: this.$langStore.lang === "ru" ? "Россия, Приморский край, с.Богуславец, ул. Колхозная 10" : "Russia, Primorsky Territory, village Boguslavets, st. Kolkhoznaya 10",
        }
    },
    setup() {
        const map = shallowRef(null);
        return {
            map,
        }
    }
}
</script>

<style scoped lang="scss">
a {
    color: #fff;
}

.footer {
    padding: 20px;
    background-color: #333;
    color: #fff;
}

.grid-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
}

.block {
    align-self: center;
    justify-self: center;
}

.circle-image {
    border-radius: 50%;
    width: 100%;
}

.marker {
    position: relative;
    width: 20px;
    height: 20px;
    background: #ff0000;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    text-align: center;
    color: #fff;
    font-weight: bold;
    line-height: 20px;
}

.contacts-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    ul {
        list-style: none;
        text-align: center;
    }
}

@media (max-width: 1024px) {
    .grid-wrapper {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .grid-wrapper {
        grid-template-columns: 1fr;
    }
}
</style>