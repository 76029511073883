import { defineStore } from 'pinia'

export const useLangStore = defineStore('lang', {
  state: () => {
    if (localStorage.getItem("langMalaysia")) {
      return { lang: localStorage.getItem("langMalaysia"), image: localStorage.getItem("langImage") }
    } else {
      return { lang: "ru", image: "en" }
    }
    
  },
  // could also be defined as
  // state: () => ({ lang: "ru" })
  actions: {
    change() {
      this.lang === "ru" ? this.lang = "en" : this.lang = "ru";
      this.image === "ru" ? this.image = "en" : this.image = "ru";
      localStorage.setItem("langMalaysia", this.lang);
      localStorage.setItem("langImage", this.image);
    },
  },
})