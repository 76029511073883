<template>
  <header>
    <!-- Десктопное меню -->
    <nav class="desktop-menu">
      <ul>
        <li><router-link to="/">{{ home }}</router-link></li>
        <li><router-link to="/products">{{ products }}</router-link></li>
        <li><router-link to="/species">{{ species }}</router-link></li>
        <li><router-link to="/contacts">{{ contacts }}</router-link></li>
        <li><router-link to="/gallery">{{ gallery }}</router-link></li>
        <li><a @click.prevent="handleLang" class="lang" href="/">{{ lang }}</a></li>
      </ul>
    </nav>

    <!-- Мобильное меню -->
    <div class="mobile-menu">
      <button class="hamburger" @click="toggleMobileMenu">
        <span></span>
        <span></span>
        <span></span>
      </button>
      <div class="mobile-menu-content" :class="{ open: isMobileMenuOpen }">
        <button class="close-button" @click="toggleMobileMenu">
          <span>&times;</span>
        </button>
        <ul>
          <li><router-link to="/">{{ home }}</router-link></li>
          <li><router-link to="/products">{{ products }}</router-link></li>
          <li><router-link to="/species">{{ species }}</router-link></li>
          <li><router-link to="/contacts">{{ contacts }}</router-link></li>
          <li><router-link to="/gallery">{{ gallery }}</router-link></li>
          <li><a @click.prevent="handleLang" class="lang" href="/">{{ lang }}</a></li>
        </ul>
      </div>
      <div class="overlay" :class="{ open: isMobileMenuOpen }" @click="toggleMobileMenu"></div>
    </div>
  </header>
</template>

<script>
import {useLangStore} from '@/stores/lang';

export default {
  name: "HeaderComponent",
  data() {
    return {
      isMobileMenuOpen: false,
      langStore: useLangStore(),
      lang: this.$langStore.lang === "ru" ? "EN" : "RU",
      home: this.$langStore.lang === "ru" ? "Главная" : "Home",
      products: this.$langStore.lang === "ru" ? "Продукция" : "Products",
      species: this.$langStore.lang === "ru" ? "Породы" : "Species",
      contacts: this.$langStore.lang === "ru" ? "Контакты" : "Contacts",
      gallery: this.$langStore.lang === "ru" ? "Галерея" : "Gallery",
    }
  },
  methods: {
    toggleMobileMenu() {
      this.isMobileMenuOpen =!this.isMobileMenuOpen
    },
    handleLang() {
      this.langStore.change();
      this.$router.go();
    }
  }
}
</script>

<style scoped lang="scss">
.desktop-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #fff;
  color: #333;
}

.desktop-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  margin: 0 auto;
}

.desktop-menu li {
  margin-right: 10px;
  padding-right: 10px;

  &:not(:last-of-type) {
    border-right: 1px solid black;
  }
}

.desktop-menu a {
  color: #333;
  text-decoration: none;
}

.mobile-menu {
  display: none;
}

.hamburger {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin-left: auto;
}

.hamburger span {
  display: block;
  width: 20px;
  height: 2px;
  background-color: #333;
  margin-bottom: 5px;
}

.mobile-menu-content {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100vh;
  background-color: #333;
  padding: 1rem;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 100;
}

.mobile-menu-content.open {
  transform: translateX(0);
}

.mobile-menu-content ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.mobile-menu-content li {
  margin-bottom: 10px;
}

.mobile-menu-content a {
  color: #fff;
  text-decoration: none;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
  }

 .overlay.open {
    display: block;
    z-index: 50;
  }

 .close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: transparent;
    border: none;
    padding: 10px;
    cursor: pointer;
    color: #fff;
  }

 .close-button span {
    font-size: 24px;
  }

@media (max-width: 768px) {
 .desktop-menu {
    display: none;
  }
 .mobile-menu, .hamburger {
    display: block;
  }
}
</style>